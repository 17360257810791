@import '../../../styles/abstract';

.labelWrapper {
  position: relative;
  display: block;

  > span {
    display: inline-block !important;
    color: $primary;
  }

  .input {
    margin-top: 4px;
    background-color: $input-background;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 0 12px;
    height: 38px;
    width: 100%;
    font-size: 15px;

    &.error {
      border: 1px solid $red;

      background-color: lighten($red, 40);
    }

    &::placeholder {
      color: rgba(123, 129, 143, 0.54);
      font-size: 15px;
    }
  }
}
