@import '../../../styles/abstract';

.wrapper {
  width: 20%;
  top: 0;
  height: calc(100vh - 72px);
  overflow: hidden;
  position: absolute;
  right: 0;
  border-left: 1px solid $border-color;
}
