@import '../../../styles/abstract';

.dropDown {
  position: absolute;
  display: block;
  z-index: 1000;
  width: auto;
  background-color: $light;
  border: 1px solid $border-color;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);

  .arrow,
  .arrow::before {
    z-index: 900;
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    left: 11px;
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
  }

  &[data-popper-placement$='end'] > .arrow {
    right: 32px;
    left: initial;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > .arrow {
    right: -4px;
  }

  &[data-popper-placement^='right'] > .arrow {
    left: -4px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  &.top-right {
    left: 2 0px;
    bottom: calc(100% + 10px);

    &:before {
      top: 100%;
      right: 20px;
      border: 6px solid transparent;
      border-bottom: none;
      border-top-color: $light;
      filter: drop-shadow(0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }
  }

  &.top-left {
    left: -70px;
    bottom: calc(100% + 10px);

    &:before {
      top: 100%;
      left: 20px;
      border: 6px solid transparent;
      border-bottom: none;
      border-top-color: $light;
      filter: drop-shadow(0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }
  }

  &.bottom-left {
    right: -16px;
    top: 32px;

    &:before {
      bottom: 100%;
      right: 20px;
      border: 6px solid transparent;
      border-top: none;
      border-bottom-color: $light;
      filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }
  }

  .smallMenu {
    width: auto;
  }
}
