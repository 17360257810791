@import '../../../styles/abstract';

.modalWrapper {
  padding: 32px 24px;

  .elementWrapper {
    margin-bottom: 24px;

    .error {
      display: block;
      margin-top: 8px;
      font-size: 13px;
      color: $red;
    }
  }
}
