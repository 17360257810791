@import '../../../styles/abstract';

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .modalContent {
    width: 100%;
    max-width: 480px;
    border-radius: 12px;
    background-color: $light;

    .modalHeader {
      padding: 24px;
      border-bottom: 1px solid $border-color;

      h3 {
        font-size: 20px;
      }
    }

    form {
      padding: 24px 24px 80px 24px;

      .err {
        color: red;
      }
    }

    .modalFooter {
      border-top: 1px solid $border-color;
      padding: 24px;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 12px;
      }
    }
  }
}
