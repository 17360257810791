@import '../../styles/abstract';

.notificationsBadge {
  position: relative;
  font-size: 12px;

  img {
    width: 32px;
  }

  span {
    background-color: #29b9d0;
    border-radius: 10px;
    min-width: 15px;
    min-height: 15px;
    color: #fff;
    font-size: 11px;
    text-align: center;
    line-height: 15px;
    font-weight: 600;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
