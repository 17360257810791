@import '../../styles/abstract';

.wrapper {
  position: relative;
  padding-left: 60px;

  @media only screen and (max-width: 769px) {
    padding-left: 0;
  }
}

:global(.sidebarExpanded) {
  padding-left: 360px;

  @include respond-to('xl') {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 1920px) {
  :global(.sidebarExpanded) {
    padding-left: 480px;
  }
}
