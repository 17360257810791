@import '../../styles/abstract';

.wrapper {
  position: relative;

  .modal {
    width: 100%;
    position: absolute;
    margin-top: 13px;
    background-color: $light;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);
    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 100%;
      left: 24px;
      border: 6px solid transparent;
      border-top: none;
      border-bottom-color: $light;
      filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }

    .modalBody {
      padding: 20px 0;

      ul {
        li {
          cursor: pointer;
          padding: 4px 20px;
          font-size: 15px;
        }
      }

      span {
        padding-left: 20px;
        font-size: 14px;
        color: $text-gray;
      }
    }

    .modalFooter {
      border-top: 1px solid $border-color;
      text-align: center;
      padding: 20px 0;

      span {
        font-size: 14px;
        color: $primary;
        cursor: pointer;
      }
    }
  }

  .newUserButton {
    display: inline-block;
    margin-top: 5px;
    background-color: lightblue;
    padding: 5px 10px;
    cursor: pointer;
  }

  .selectedUsers {
    margin-top: 16px;

    li {
      display: flex;
      align-items: center;
      padding: 8px 0;
      position: relative;

      &:hover {
        background-image: linear-gradient(to left, #f9f9f9 37%, rgba(249, 249, 249, 0));

        .selectedOptions {
          display: inline-flex;
          position: absolute;
          right: 15px;
          span {
            padding-left: 15px;
          }
        }
      }

      .selectedImage {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: lightblue;
        margin-right: 16px;
        position: relative;

        span {
          position: absolute;
          right: -2px;
          bottom: -2px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #fff6cc;
          box-shadow: 0 3px 24px 0 rgba(123, 129, 143, 0.28);
        }

        // .ownerBadge {
        //   display: block;
        //   position: absolute;
        //   right: -2px;
        //   bottom: -2px;
        //   width: 16px;
        //   height: 16px;
        //   border-radius: 50%;
        //   background-color: #fff6cc;
        //   box-shadow: 0 3px 24px 0 rgba(123, 129, 143, 0.28);
        //   text-align: center;
        // }

        svg {
          width: 12px;
          height: 12px;
        }

        svg * {
          stroke: #ffab0b;
        }
      }

      .selectedInfo {
        & > div {
          display: flex;
          align-items: center;

          .selectedName {
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
          }

          .ownerBadge {
            margin-left: 8px;
            font-size: 12px;
            font-weight: 500;
            background-color: #fff6cc;
            border-radius: 10px;
            display: inline-block;
            padding: 2px 8px;
          }
        }

        .selectedRole {
          font-size: 13px;
          color: $text-gray;
        }
      }

      .selectedOptions {
        display: none;
      }
    }
  }
}
