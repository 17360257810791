@import './../../styles/abstract';

.wrapper {
  position: relative;

  :global(.chat-manager) {
    @include respond-to('xl') {
      display: none;
    }
  }

  @include respond-to('xl') {
    &.chatOpen {
      :global(.chat-manager) {
        display: block;
        width: 40%;
        height: 100%;
        background: $light;
        box-shadow: 0 5px 8px 0 rgba(123, 129, 143, 0.28);
        z-index: 1000;
      }
    }
  }

  @include respond-to('md') {
    &.chatOpen {
      :global(.chat-manager) {
        width: 80%;
      }
    }
  }
}
