@import '../../styles/abstract';

.notificationToast {
  position: relative;
  top: 0;
  height: auto;
  margin-bottom: 12px;
  box-shadow: 0px 3px 10px #7b818f1f;
  border: 1px solid #7b818f1f;
  background-color: #fff;
  padding: 16px 12px 12px 14px;
  border-radius: 8px;
  width: 335px;
  animation: iconShow 0.2s linear;
  transition: all 0.2s;

  @include respond-to('md') {
    width: 100%;
  }

  @keyframes iconShow {
    0% {
      top: 5px;
    }
    100% {
      top: 0;
    }
  }

  .breadcrumb {
    display: flex;
    justify-content: space-between;

    small {
      font-size: 11px;
      color: $gray;
    }

    svg {
      position: relative;
      top: -5px;
      right: -5px;
    }
  }

  .messageContent {
    display: flex;

    .avatarWrapper {
      margin-right: 16px;
    }

    .messageInfo {
      font-size: 14px;
      color: $gray;
      padding-top: 6px;

      strong {
        color: #0d101f;
      }
    }
  }
}
