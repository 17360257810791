@import '../../styles/abstract';

.wrapper {
  margin-top: 32px;
  padding: 0 32px 92px 0;

  .content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: $light;
    border: solid 1px $border-color;
    box-shadow: 0 3px 24px 0 rgba(123, 129, 143, 0.08);
    border-radius: 12px;

    h4 {
      margin-bottom: 32px;
    }

    .infoCol {
      width: 35%;
      border-right: 1px solid $border-color;
      padding: 32px 24px 32px 32px;

      @include respond-to('xl') {
        width: 60%;
      }

      .elementWrapper {
        margin-bottom: 32px;

        .error {
          display: block;
          margin-top: 8px;
          font-size: 13px;
          color: $red;
        }
      }

      .fileHeading {
        margin-top: 52px;
      }
    }

    .teamCol {
      width: 65%;
      padding: 32px 32px 32px 24px;

      @include respond-to('xl') {
        width: 40%;
      }

      .row {
        display: flex;
        justify-content: space-between;

        @include respond-to('xl') {
          flex-direction: column;
        }

        .col {
          width: 50%;

          &:first-child {
            padding-right: 24px;
            border-right: 1px solid $border-color;

            @include respond-to('xl') {
              padding-right: 0;
            }
          }
          &:last-child {
            padding-left: 24px;

            @include respond-to('xl') {
              padding-top: 44px;
              padding-left: 0;
            }
          }

          @include respond-to('xl') {
            width: 100%;
          }
        }
      }
    }

    .fileList {
      margin-top: 15px;

      .file {
        position: relative;
        font-size: 13px;
        padding: 5px 0;
        display: flex;
        align-items: center;

        .fileDetail {
          margin-left: 8px;
          span {
            margin-top: 6px;
            display: block;
            font-size: 12px;
            color: $text-gray;
          }
        }

        .fileRemove {
          display: none;
          position: absolute;
          right: 0;
          // background-color: red;
          cursor: pointer;
        }

        &:hover .fileRemove {
          display: block;
        }
      }
    }
  }
}

.bottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $light;
  border-top: 1px solid $border-color;
  text-align: right;
  padding: 12px 24px 12px 84px;
  display: flex;
  justify-content: flex-end;
  // padding-left: 84px;
  // padding-right: 24px;

  .bottomBarDelete {
    display: flex;
    align-items: center;
    color: $text-gray;
    padding: 0 12px;

    &:hover {
      background-color: $border-color;
      border-radius: 8px;
    }
    span {
      margin-left: 8px;
      cursor: pointer;
      font-size: 13px;
    }
  }

  &.multipleElements {
    justify-content: space-between;
  }

  & > div {
    display: flex;

    span {
      margin-right: 12px;
    }
  }
}

.deleteInfo {
  padding: 40px 107px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .deleteIcon {
    border: 1px solid transparent;
    padding: 12px;
    border-radius: 50%;
    background-color: $light;
    box-shadow: 0 0 25px 0 rgba(123, 129, 143, 0.16);
    margin-bottom: 32px;
  }
}

.operationsInfo {
  padding: 40px;
  background-color: $input-background;
  p {
    margin-bottom: 32px;
  }

  ul li {
    color: $text-gray;
    margin-top: 18px;
    display: flex;
    align-items: center;

    span {
      margin-right: 12px;
      background-color: $red;
      border-radius: 4px;
    }
  }
}
