@import '../../../../styles/abstract';

.messageWrapper {
  padding: 16px;
  border-bottom: 1px solid rgba(#0d101f, 0.06);
  background-color: rgba(#29b9d0, 0.08);

  &.readed {
    background-color: #fff;
  }

  small {
    font-size: 11px;
    color: #7b818f;
    text-decoration: underline;
  }

  .messageContent {
    position: relative;
    margin-top: 16px;
    padding-right: 15px;
    display: flex;

    .messageInfo {
      margin-left: 16px;
      font-size: 14px;

      span {
        color: #7b818f;
      }

      .messageData {
        display: block;
        margin-top: 12px;
        font-size: 11px;
        color: rgba(123, 129, 143, 0.54);
      }

      .badge {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        right: 0;

        &.yellow {
          background-color: #ffab0b;
        }
        &.green {
          background-color: #1ec97a;
        }
        &.red {
          background-color: #f42929;
        }
      }
    }
  }
}
