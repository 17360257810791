@import '../../../../../styles/abstract';

.left {
  display: flex;
  align-items: center;

  .otherApproverWrap {
    display: flex;
    align-items: center;
  }

  .new {
    display: flex;
    align-items: center;
    span {
      width: 8px;
      height: 8px;
      margin-right: 5px;
      border-radius: 50%;
      background-color: #b7bbc2;
    }
  }
  & > span {
    display: inline-block;
    padding: 5px 10px;
    color: $gray;

    &.versionWrapper {
      position: relative;
      border-right: 1px solid $border-color;
      border-radius: 6px;
      color: $gray;

      &.versionsBox {
        border: 1px solid gray;
        border-radius: 6px;
        padding: 5px 10px;
        margin-top: 6px;
        background-color: $body-background;
      }

      & > div {
        left: 10px;
        bottom: 100%;
        position: absolute;
        border: 1px solid #000;
        background-color: #fff;
        width: 45px;
        padding: 7px 0;
        text-align: center;

        span {
          cursor: pointer;
          display: block;
        }
      }
    }

    &.hidden {
      position: relative;
      opacity: 0.2;
    }

    &.hidden:before {
      content: '/';
      position: absolute;
      display: block;
      left: 25px;
      top: 0;
      font-size: 30px;
    }
  }

  .notCurrentApproverWrap {
    display: flex;

    .approverBox {
      position: relative;
      margin-right: 5px;

      .status {
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        bottom: 0;
        right: -2px;

        &.waiting {
          background-color: $orange;
        }
        &.approved {
          background-color: $green;
        }
        &.rejected {
          background-color: $red;
        }
      }
    }
  }
  .currentApproverWrap {
    display: flex;

    .approverBox {
      position: relative;
      margin-right: 5px;

      .status {
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        bottom: 0;
        right: -2px;

        &.waiting {
          background-color: $orange;
        }
        &.approved {
          background-color: $orange;
        }
        &.rejected {
          background-color: $red;
        }
      }
    }
  }
}
