@import '../../styles/abstract';

.bottombarWrapper {
  display: none;
  z-index: 20;
  height: 56px;
  width: 100%;
  background-color: $light;
  justify-content: space-around;
  border-top: 1px solid $border-color;
  position: fixed;
  bottom: 0;

  @include respond-to('md') {
    display: flex;

    .bottombarLink {
      display: flex;
      align-items: center;
      margin-left: -36px;
      margin-right: -36px;

      a,
      span {
        display: block;

        &:global(.active) {
          background-color: $light;
          position: relative;

          svg * {
            stroke: $primary;
          }

          &::after {
            content: '';
            position: absolute;
            top: -16px;
            left: -4px;
            background-color: $primary;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            width: 32px;
            height: 4px;
          }
        }
      }

      & > span {
        margin: 0 36px;
      }

      .notificationIcon {
        cursor: pointer;
        position: relative;

        span {
          position: absolute;
          bottom: -3px;
          left: 13px;
          width: 15px;
          height: 15px;
          background-color: $primary;
          border-radius: 50%;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $light;
        }
      }
    }
  }
}
