@import '../../styles/abstract';

.row {
  padding: 0 15px;
}
.campaignTopBar {
  height: 148px;
  background-color: $light;
  border-bottom: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .campaignTopBarTitle {
    display: flex;
    padding: 23px;
    font-size: 24px;
    align-items: center;
    justify-content: space-between;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      margin: 0 18px;
      padding: 12px 6px;
      font-size: 16px;
      line-height: 21px;

      color: #000;
      &.active {
        border-bottom: 2px solid #000;
        color: #000;
      }
    }
  }
}
.tit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
  margin-right: 30px;
  position: relative;

  .newCampaign {
    @include respond-to('md') {
      display: none;
    }
  }
}
.welcomeTitle {
  margin-bottom: 30px;
  text-align: center;
  font-size: 20px;
  color: $gray;
  padding: 0 24px;
}
.campaignsWrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  :global(.sidebarExpanded) & {
    width: 100%;
  }
  @include respond-to('md') {
    padding-bottom: 64px;
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  width: calc(1360px + 48px);

  li {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 680px;
    margin: 12px;

    a {
      display: block;
      border: 1px solid $border-color;
      background-color: $light;
      border-radius: 12px;
      box-shadow: 0px 3px 8px #7b818f0a;
      transition: transform 0.2s ease-out;

      &:hover {
        box-shadow: 0px 3px 32px #7b818f3d;
        transition: all 0.2s;

        .headerCampaign {
          background-size: calc(100% + 16px);
          transition: all 0.2s;
        }
      }
    }
  }

  @media only screen and (max-width: 1919px) {
    width: calc(1080px + 48px);

    li {
      flex-basis: 540px;
    }
  }

  @include respond-to('xl') {
    padding: 0 64px;
    width: auto;
    li {
      flex-basis: calc(50% - 24px);
    }
  }

  @include respond-to('md') {
    padding: 0 114px;
    li {
      flex-basis: calc(100% - 24px);
    }
  }

  @include respond-to('sm') {
    padding: 0 24px;
  }
}

.oneItem {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;

  li {
    width: calc(50% - 24px);
    margin: 12px;

    @include respond-to('lg') {
      width: calc(100% - 24px);
    }

    a {
      display: block;
      border: 1px solid $border-color;
      background-color: $light;
      border-radius: 12px;
      box-shadow: 0px 3px 8px #7b818f0a;
      transition: transform 0.2s ease-out;

      &:hover {
        box-shadow: 0px 3px 32px #7b818f3d;
        transition: all 0.2s;

        .headerCampaign {
          background-size: calc(100% + 16px);
          transition: all 0.2s;
        }
      }
    }
  }
}

:global(.sidebarExpanded) {
  .list {
    width: auto;
    @include respond-to('xxl') {
      width: calc(1360px - 48px);
      li {
        flex-basis: calc(50% - 24px);
      }
    }
    @include respond-to('sm') {
      li {
        flex-basis: calc(100% - 24px) !important;
      }
    }
  }
}
