@import '../../../styles/abstract';

.wrapper {
  position: absolute;
  top: 0;
  width: 20%;
  height: calc(100vh - 72px);
  border-right: 1px solid $border-color;
  background-color: $light;

  .folderMeta {
    display: none;
  }

  @include respond-to('xl') {
    width: 30%;
  }

  .row {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid $border-color;
    background: $light;
  }

  .contentWrapper {
    padding: 0 20px;
    background-color: $light;

    .stepsWrapper {
      margin: 32px 0;

      .stepsEditWrapper {
        display: flex;
        position: relative;

        label {
          width: 100%;
        }

        .stepsEditButton {
          position: absolute;
          right: 10px;
          top: 12px;
        }

        button {
          margin-left: 8px;
          position: absolute;
        }
      }

      span {
        color: $gray;
        font-size: 13px;
      }

      p {
        font-size: 15px;
        margin-top: 8px;
      }

      .stepsList {
        display: flex;
        margin-bottom: 24px;
        margin-top: 12px;

        & > span {
          flex: 1 0 auto;
          height: 3px;

          background-color: rgba($primary, 0.3);
          margin-right: 6px;

          &.completed {
            background-color: $primary;
          }
        }
      }

      .stepsDeadline {
        color: $gray;
        margin-bottom: 32px;

        span {
          margin: 8px 0;
          display: block;
          font-size: 12px;
        }
      }
    }

    .formatsFolders {
      list-style-type: none;
      margin-bottom: 30px;

      .folderInfo {
        align-items: center;
        display: flex;
        gap: 24px;
      }

      li {
        cursor: pointer;
        margin: 0 -20px;
        padding: 32px 20px;
        display: flex;
        align-items: center;

        font-size: 14px;
        font-weight: 500;
        border-top: 1px solid $border-color;

        &:last-child {
          border-bottom: 1px solid $border-color;
        }

        &.current,
        &:hover {
          background-color: $body-background;
        }

        &.current {
          &::after {
            content: '';
            position: absolute;
            right: 0;
            background-color: $primary;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            width: 4px;
            height: 32px;
          }
        }

        span {
          margin-right: 8px;
        }
        &:last-child {
          // border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        }

        // &:global(.active) {
        //   &::after {
        //     content: '';
        //     position: absolute;
        //     right: 0;
        //     background-color: $primary;
        //     border-top-left-radius: 4px;
        //     border-bottom-left-radius: 4px;
        //     width: 4px;
        //     height: 32px;
        //   }
        // }
      }
    }
  }

  @include respond-to('md') {
    display: none;

    &.infoOpen {
      display: block;
      width: 100%;
      position: initial;
      border: none;
      background: $body-background;

      .stepsWrapper {
        display: none;
      }

      .contentWrapper {
        background: transparent;

        .newFolder {
          @include respond-to('md') {
            display: none;
          }
        }
      }

      .formatsFolders {
        margin: 24px;
        background: transparent;

        li {
          flex-direction: column;
          box-shadow: 0 3px 24px 0 rgba(123, 129, 143, 0.08);
          border: 1px solid $border-color;
          background-color: $light;
          border-radius: 12px;
          margin-bottom: 8px;
          padding: 0;
          align-items: start;

          &.current {
            background: $light;
          }
        }
      }

      .folderInfo,
      .folderMeta {
        padding: 14px 24px;
        width: 100%;
      }

      .folderMeta {
        display: block;
        padding: 16px 24px;
        border-top: 1px solid $border-color;

        .muted {
          color: $text-gray;
          font-size: 12px;
        }
      }
    }
  }
}

.form {
  padding: 24px 24px 80px 24px;
}
.updateStepInfo {
  padding: 32px;
}

.error {
  display: block;
  margin-top: 8px;
  font-size: 13px;
  color: $red;
}

.deleteInfo {
  padding: 40px 107px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .deleteIcon {
    border: 1px solid transparent;
    padding: 12px;
    border-radius: 50%;
    background-color: $light;
    box-shadow: 0 0 25px 0 rgba(123, 129, 143, 0.16);
    margin-bottom: 32px;
  }
}

.operationsInfo {
  padding: 40px;
  background-color: $input-background;
  p {
    margin-bottom: 32px;
  }

  ul li {
    color: $text-gray;
    margin-top: 18px;
    display: flex;
    align-items: center;

    span {
      margin-right: 12px;
      background-color: $red;
      border-radius: 4px;
    }
  }
}
