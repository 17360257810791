@import '../../../styles/abstract';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .singleChatRow {
    position: relative;
    height: 54px;
    flex: 0 0 54px;
    background-color: $light;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    padding: 0 8px;

    .chatBack {
      padding: 8px;

      &:hover {
        background-color: $border-color;
        border-radius: 8px;
      }
    }

    h3 {
      width: 100%;
    }
  }

  .doubleTabs {
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid $border-color;

    li {
      cursor: pointer;
      margin: 0 15px;
      padding: 16px 5px;
      border-bottom: 3px solid transparent;
      position: relative;
      top: 1px;

      &.active {
        border-color: #0d101f;
      }
    }
  }

  .messages {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    padding: 8px;
    background-color: $body-background;

    h5 {
      background-color: $light;
      z-index: 10;
      top: 0;
      left: 15px;
      width: calc(100% - 30px);
      position: absolute;
      display: block;
      padding-bottom: 10px;
      text-align: center;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    }

    ul {
      list-style-type: none;

      li {
        width: auto;
        margin-top: 16px;

        .date {
          color: $text-gray;
          font-size: 11px;
        }

        &.fromOther {
          display: flex;

          .content {
            width: calc(100% - 50px);
            position: relative;
            padding: 16px 19px;
            border-radius: 8px;
            border-top-left-radius: 0;
            background-color: #eeeeee;
            box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.08);
            margin-left: 20px;

            &:before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              right: 100%;
              top: 0;
              border-bottom: 14px solid transparent;
              border-right: 14px solid #eeeeee;
              filter: drop-shadow(-0.1625rem 0.0625rem 0.1625rem rgba(123, 129, 143, 0.08));
            }

            .author {
              display: flex;
              justify-content: space-between;
              font-size: 13px;
              line-height: 16px;
              margin-bottom: 12px;

              strong {
                font-weight: 600;
              }
            }
          }
        }

        &.fromMe {
          position: relative;
          margin-left: auto;
          margin-right: 0;

          display: flex;

          border-radius: 8px;

          position: relative;

          .userImage {
            margin-top: 8px;
          }

          .content {
            width: calc(100% - 50px);
            position: relative;
            padding: 16px 19px;
            border-radius: 8px;
            border-top-right-radius: 0;
            background-color: $light;
            box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.08);
            margin-right: 20px;

            div {
              justify-content: space-between;
              font-size: 13px;
              line-height: 16px;
              margin-bottom: 3px;
            }

            &:before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              left: 100%;
              top: 0;
              border-bottom: 14px solid transparent;
              border-left: 14px solid $light;
              filter: drop-shadow(0.1625rem 0 0.0625rem rgba(123, 129, 143, 0.08));
            }

            .contentTop {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 12px;

              .contentTopIcon {
                padding: 6px;

                &:hover {
                  border-radius: 8px;
                  background-color: $border-color;
                }
              }
            }
          }

          div {
            justify-content: space-between;
            font-size: 14px;
            margin-bottom: 3px;
          }
        }

        &.fromSystem {
          font-size: 12px;
          border: 1px solid $border-color;
          border-radius: 8px;
          padding: 4px 16px;
          text-align: center;
          color: $text-gray;
        }

        .message {
          font-size: 13px;
          line-height: 16px;
          color: $text-dark;
          span {
            color: $primary;
            font-weight: 500;
          }
        }
      }
    }
  }

  .newMessageWrapper {
    border-top: 1px solid $border-color;
    padding: 13px;
    padding-right: 6px;
    right: 0;
    bottom: 0;
    background-color: $light;
    display: flex;

    label {
      margin-right: 10px;
    }

    button {
      background: transparent;
      border: none;
      min-height: 22px;
      padding: 0 8px;

      span {
        margin: 0;
      }

      svg * {
        stroke: $primary;
      }
    }
  }
}
