@import '../../../styles/abstract';

.titleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  h3 {
    line-height: 1.25;
    font-size: 16px;
    font-weight: 600;
  }

  .titleBarIcons {
    display: flex;
    align-items: center;

    span {
      margin-left: 18px;
    }
  }
}

.subTitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: $text-gray;
  font-weight: 500;
  font-size: 13px;
  margin-top: 28px;
  margin-bottom: 12px;

  .titlePhaseName {
    display: block;
    width: 450px;
    padding-left: 14px;
  }

  span {
    color: $text-gray;
    font-weight: 500;
    font-size: 13px;
  }

  .titleDays {
    margin-left: auto;
  }
}

.errorsWrapper {
  padding: 0 20px;
}

.content {
  .stepsList {
    position: relative;
    border-top: 1px solid $border-color;

    .phaseName {
      width: 400px;
      padding-left: 4px;
    }

    .inputDate {
      padding-left: 40px;
    }

    .stepsItem {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 22px 8px 4px;
      border-bottom: 1px solid $border-color;

      .options {
        display: none;
        position: absolute;
        justify-content: flex-end;
        align-items: center;
        width: 80px;
        right: 0;
        bottom: 0;
        top: 0;
        background: #f9f9f9;
        padding-right: 12px;

        background: linear-gradient(
          to left,
          rgba(249, 249, 249, 1) 40%,
          rgba(125, 185, 232, 0) 100%
        );

        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      /* &:first-child {
        .phaseName::before {
          content: 'Fase';
        }
        .inputDate::before {
          content: 'Data chiusura prevista';
        }
        .phaseDays::before {
          content: 'Giorni';
        }

        .phaseName::before,
        .inputDate::before,
        .phaseDays::before {
          position: absolute;
          top: -24px;
          font-size: 13px;
          color: $text-gray;
          font-weight: 500;
        }
      } */

      .phaseDays {
        color: #7b818f;
        font-size: 15px;
        margin-left: auto;
      }

      &:hover {
        background: #f9f9f9;

        .options {
          display: flex;
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // margin-top: 30px;
  padding: 20px;
}
