@import '../../../../styles/abstract';

.sfaWrapper {
  padding: 24px;

  ul {
    margin-bottom: 32px;
  }

  .sfaAlert {
    // width: 432px;
    // height: 64px;
    // margin: 24px 24px 23px;
    display: flex;
    color: black;
    font-size: 13px;
    padding: 16px 32px 16px 12px;
    margin-bottom: 23px;
    // opacity: 0.24;
    border-radius: 8px;
    background-color: lighten($orange, 40);

    span {
      margin-right: 16px;
    }
  }

  & .sfaWrapperInfo {
    margin-bottom: 32px;
  }

  & .sfaInfo {
    display: block;
    margin: 12px 0;
    color: $text-gray;
  }
}
