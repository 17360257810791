@import '../../styles/abstract';

.row {
  display: flex;
  flex-wrap: wrap;
  // padding: 0 15px;
  height: 100vh;

  .newCreativity {
    @include respond-to('md') {
      display: none;
    }
  }

  h3 {
    margin-bottom: 20px;
  }

  // .colCamps {
  //   width: 20%;
  //   padding-right: 30px;

  //   ul {
  //     padding: 0;
  //     list-style-type: none;

  //     li a {
  //       display: block;
  //       border: 1px solid rgba(0, 0, 0, 0.3);
  //       padding: 10px;
  //       margin-bottom: 10px;

  //       &:hover {
  //         background-color: rgba(0, 0, 0, 0.1);
  //       }

  //       &.active {
  //         background-color: lightblue;
  //       }
  //     }
  //   }
  // }

  .colInfo {
    width: 75%;
    border-right: 1px solid $border-color;
    // padding: 5px 30px;
    background-color: #f9f9f9;
  }
  .creativityWrapperTitle {
    background-color: $light;
    height: 54px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    border-bottom: 1px solid $border-color;

    h3 {
      margin-bottom: 0;
    }
  }
  .colInfoContent {
    padding: 5px 30px;
    display: flex;
    // justify-content: center;
    position: relative;
    flex-direction: column;
    align-items: center;
  }

  .colOther {
    // padding: 10px 20px;
    width: 25%;
    background-color: $light;
    .colOtherContent {
      padding: 10px 20px;

      p {
        color: $text-gray;
      }

      .desc h3 {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.33;
      }
    }

    .campaignOptions {
      border-bottom: 1px solid $border-color;
      max-height: 72px;
    }

    h2 {
      margin-bottom: 30px;
    }
    h3 {
      margin-top: 20px;
    }

    .rowOther {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .desc {
      border-bottom: 1px solid $border-color;
      p {
        margin-bottom: 20px;
      }
    }
    .filesWrapper {
      border-bottom: 1px solid $border-color;

      .filesBox {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .fileBox {
          width: auto;
          min-height: 30px;
          display: inline-block;
          padding: 6px 25px;
          margin-bottom: 8px;
          margin-right: 8px;
          border: 1px solid $border-color;
          color: $gray;
          border-radius: 20px;
        }
      }
    }

    .teamWrapper {
      .internalTeam,
      .externalTeam {
        border-bottom: 1px solid $border-color;

        h3 {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 16px;
          line-height: 1.33;
        }
      }
      .team {
        display: flex;

        list-style-type: none;

        span {
          margin-right: 8px;
        }

        .user {
          margin-right: 5px;
          margin-bottom: 20px;
          display: flex;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: lightblue;
          position: relative;

          span {
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: #fff6cc;
            position: absolute;
            right: -10px;
            bottom: -1px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 8.5px;
              height: 8.9px;
            }
          }
        }
      }
    }
  }

  @include respond-to(md) {
    .colOther {
      display: none;
    }

    .colInfo {
      width: 100%;
    }

    .colInfoContent {
      padding: 24px;
    }
  }
}

.deleteInfo {
  padding: 40px 107px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .deleteIcon {
    border: 1px solid transparent;
    padding: 12px;
    border-radius: 50%;
    background-color: $light;
    box-shadow: 0 0 25px 0 rgba(123, 129, 143, 0.16);
    margin-bottom: 32px;
  }
}

.operationsInfo {
  padding: 40px;
  background-color: $input-background;
  p {
    margin-bottom: 32px;
  }

  ul li {
    color: $text-gray;
    margin-top: 18px;
    display: flex;
    align-items: center;

    span {
      margin-right: 12px;
      background-color: $red;
      border-radius: 4px;
    }
  }
}
