@import '../../../styles/abstract';

.input {
  :global(textarea) {
    padding: 6px 12px;
    border: none;
  }
}

.mentions--multiLine .mentions__control {
  padding: 9px 12px;
}

.mentions--multiLine .mentions__input {
  border: 1px solid $border-color;
  border-radius: 8px;
  background: $input-background !important;
  width: 100%;
  padding: 9px 12px;
  outline: 0;
}

.mentions__suggestions__list {
  border: 1px solid $border-color;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);
  overflow: auto;
  position: relative;
  min-width: 250px;
}

.mentions__suggestions__item {
  background-color: $light;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 12px;

  .itemInner {
    display: flex;
  }

  .itemAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: lightblue;
    margin-right: 16px;
  }

  .itemInfo {
    color: $text-gray;
    font-size: 13px;
    h4 {
      font-size: 15px;
      font-weight: 500;
      color: $text-dark;
      margin: 4px 0;
    }
  }

  &:last-child {
    border: none;
  }
}

.mentions__suggestions__item--focused {
  background-color: $body-background;
  color: $primary;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: $primary;
  pointer-events: none;
}
