@import '../../../styles/abstract';

.editorWrapper {
  position: relative;
  overflow: hidden;
  border: solid 1px $border-color;
  border-radius: 12px;

  :global(.DraftEditor-root) {
    a {
      color: $primary !important;
    }
  }

  .modalBodyContent {
    padding: 30px;
  }

  .editorHeader {
    display: flex;
    border-bottom: solid 1px $border-color;
    background-color: #f4f4f4;
    padding: 2px 10px; // h:49px

    .fileButtons {
      display: flex;
      align-items: center;
    }

    .action {
      padding: 10px;
      cursor: pointer;
    }
  }

  .editorFooter {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    width: calc(100% - 10px);
    padding: 16px 19px;
    background-color: #fff;

    .fileBox {
      background-color: #f9f9f9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      width: calc(33.333% - 10px);
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 10px;
      border-radius: 4px;
      padding: 0 12px;

      div {
        display: flex;
        align-items: center;
      }

      span {
        display: block;
        margin-left: 8px;
        font-size: 13px;
        color: $primary;
      }
    }
  }
}
