@import '../../styles/abstract';

.lostPasswordWrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  p {
    color: $gray;
    font-size: 16px;
  }
  h2 {
    font-size: 24px;
  }

  .hero {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;

    @include respond-to('lg') {
      display: none;
    }

    .heroContent {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f9f9f9;
      margin-bottom: 80px;

      @include respond-to('xl') {
        width: 80%;
      }
    }

    .img {
      height: 372px;
      width: 512px;
      border: 1px solid #ccc;
      margin-top: 150px;
    }

    .welcome {
      width: 50%;
      text-align: center;
      margin-top: 124px;
      margin-bottom: 80px;

      @include respond-to('xl') {
        width: 60%;
      }

      h3,
      p {
        margin-bottom: 24px;
        line-height: 28px;
      }

      h3 {
        font-size: 24px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .formContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    @include respond-to('lg') {
      width: 100%;
    }

    .formContent {
      max-width: 360px;

      @include respond-to('lg') {
        width: 80%;
      }

      h2 {
        margin-bottom: 42px;
      }
      p {
        margin-bottom: 52px;
      }

      .btn {
        width: 100%;
        display: block;
        text-align: center;
      }
    }

    .formGroup {
      margin-bottom: 53px;

      label,
      input,
      span {
        display: block;
      }

      .link {
        margin-top: 48px;
        font-size: 14px;
        color: $primary;
        text-decoration: underline;
      }
    }
  }

  .err {
    color: $red;
    font-size: 12px;
    margin-top: 6px;
  }
}
