@import '../../../styles/abstract';

.customInput {
  cursor: pointer;
  position: relative;
  padding: 20px;
  display: block;
  margin-bottom: 10px;
  border-radius: 12px;
  position: relative;
  text-align: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2333353847' stroke-width='1' stroke-dasharray='3%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  p {
    margin-top: 8px;
    font-size: 14px;

    span {
      color: $primary;
    }
  }

  input {
    position: absolute;
    visibility: hidden;
  }
}

.iconInput {
  input {
    position: absolute;
    visibility: hidden;
  }
}
