@import './../../../styles/abstract';

.content {
  h3 {
    line-height: 1.25;
    font-size: 16px;
    font-weight: 600;
    padding: 30px 50px 0px 50px;
  }
  .formContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
  }
  .input {
    width: 50%;
    margin-top: 30px;
    margin-right: 30px;
  }

  .error {
    display: block;
    margin-top: 8px;
    font-size: 13px;
    color: $red;
  }
  // .inputDate {
  //   margin-top: 48px;
  //   margin-right: 30px;
  // }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  border-top: 1px solid $border-color;

  .footerContent {
    display: flex;
    align-items: center;
    padding: 20px;

    .btnTransparent {
      margin-right: 10px;
      border: 1px solid $border-color;
      border-radius: 20px;
      padding: 10px 20px;
      color: $gray;
    }
  }
}
