@import '../../../../styles/abstract';

.wrapper {
  .editorActions {
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .editorActionsDelete {
      padding: 8px;

      &:hover {
        background-color: $border-color;
        border-radius: 8px;
      }
    }

    .editorActionsRight {
      display: flex;
    }
  }
}
