@import '../../styles/abstract';

.topbarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $light;
  padding: 24px;
  border-bottom: 1px solid $border-color;
  height: 72px;

  .topbarBack {
    padding: 8px;

    &:hover {
      background-color: $border-color;

      border-radius: 8px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    h1 {
      font-size: 24px;
    }

    .subtitle {
      color: $text-gray;
      font-size: 20px;
      position: relative;
      padding-left: 16px;
      margin-left: 16px;

      &::before {
        content: '';
        width: 1px;
        background-color: $gray;
        position: absolute;
        top: -6px;
        bottom: -6px;
        left: 0;
      }
    }
  }
}
