.wrapper {
  position: relative;
  margin-top: 20px;
  .addFormatRow {
    margin: 15px auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.multipleModalContent {
  padding: 40px;
  text-align: center;
}
