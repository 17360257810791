@import '../../../../../../styles/abstract';

.approverBox {
  position: relative;
  margin-right: 5px;
  display: flex;

  .status {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    bottom: 0;
    left: 20px;

    &.waiting {
      background-color: $orange;
    }
    &.approved {
      background-color: $green;
    }
    &.rejected {
      background-color: $red;
    }
  }
}

.deleteRequest {
  display: flex;
  align-items: center;
  margin-top: 22px;
  cursor: pointer;

  span {
    color: $text-gray;
    font-size: 13px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-left: 8px;
  }
}

.approvalDropDownWrapper {
  padding: 20px 40px 30px 20px;
  font-size: 13px;

  .approvalDropDownTop {
    display: flex;
    align-items: center;
    color: $text-gray;
    line-height: 24px;

    span {
      margin-left: 8px;
    }
  }

  .approvalDropDownBody {
    padding: 18px 0;
    display: flex;
    align-items: center;

    .approver {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 16px;
      margin-left: 16px;

      .approverName {
        font-family: 'Graphik';
        font-weight: 500;
        margin-bottom: 4px;
      }

      .approverRole {
        color: $text-gray;
        font-size: 12px;
      }
    }
  }

  .status {
    display: flex;
    align-items: center;

    .approverStatus {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;

        &:before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 5px;
          border-radius: 50%;
        }

        &.waiting:before {
          background-color: $orange;
        }
        &.approved:before {
          background-color: $green;
        }
        &.rejected:before {
          background-color: $red;
        }
      }
    }

    .statusTxt {
      margin-left: 6px;
    }
  }

  .approvalDropDownBottom {
    margin-top: 27px;
  }
}

.approvalActionsWrapper {
  .approve {
    border-bottom: 1px solid $border-color;
  }

  .approve > div,
  .disapprove > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 22px 12px 12px;
    cursor: pointer;

    span {
      margin-left: 12px;
    }

    &:hover {
      background-color: $body-background;
    }
  }
}
