@import '../../../styles/abstract';

.container {
  position: relative;

  span {
    display: flex;
    align-items: center;
  }

  .dropDownMenu {
    position: absolute;
    z-index: 1000;
    right: -16px;
    top: 32px;
    width: 240px;
    background-color: $light;
    border: 1px solid $border-color;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 100%;
      right: 20px;
      border: 6px solid transparent;
      border-top: none;
      border-bottom-color: $light;
      filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }

    .dropdownItem {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 22px 16px;
      border-bottom: 1px solid $border-color;

      font-family: Graphik;
      font-size: 16px;
      font-weight: normal;

      svg {
        margin-right: 12px;
      }

      &:last-child {
        border: none;
      }
    }
  }
  &.smallMenu .dropDownMenu {
    width: auto;
  }
}
