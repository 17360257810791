@import '../../../styles/abstract';

.wrapper {
  margin-top: 4px;
  display: flex;
  background-color: $input-background;
  border-radius: 8px;
  padding: 8px 12px;
}

.input {
  border: $border-color;
  background-color: transparent;
}
