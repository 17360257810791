@import './../../styles/abstract';

.ressetPwdWrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  p {
    color: $gray;
    font-size: 16px;
  }

  h2 {
    font-size: 24px;
  }

  .hero {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    margin-bottom: 80px;

    @include respond-to('lg') {
      display: none;
    }

    .heroContent {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f9f9f9;
      margin-bottom: 80px;

      @include respond-to('xl') {
        width: 80%;
      }
    }

    .img {
      height: 372px;
      width: 512px;
      border: 1px solid #ccc;
      margin-top: 150px;
    }

    .welcome {
      width: 300px;
      text-align: center;
      margin-top: 124px;

      @include respond-to('xl') {
        width: 60%;
      }

      h3,
      p {
        margin-bottom: 24px;
        line-height: 28px;
      }

      h3 {
        font-size: 24px;
      }
      p {
        font-size: 20px;
      }
    }

    // .sliderDots {
    //   ul {
    //     list-style: none;
    //     display: flex;
    //     li {
    //       width: 6px;
    //       height: 6px;
    //       border-radius: 50%;
    //       background-color: rgb(97, 97, 93);
    //       margin-right: 4px;
    //       &.active {
    //         background-color: $primary;
    //       }
    //     }
    //   }
    // }
  }
  .formContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    @include respond-to('lg') {
      width: 100%;
    }

    .cta {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 41px;
      margin-bottom: 53px;
      @include respond-to('md') {
        flex-direction: column;
      }
    }
    .formContent {
      max-width: 360px;
      @include respond-to('lg') {
        width: 80%;
      }

      h2 {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 28px;
      }
      p {
        margin-bottom: 52px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .formGroup {
      margin-bottom: 24px;

      label,
      input,
      span {
        display: block;
      }
    }
  }

  .btn {
    display: block;
    text-align: center;
  }

  .link {
    color: $primary;
    font-size: 14px;
    text-decoration: underline;
  }

  .err {
    color: $red;
    font-size: 12px;
    display: block;
    margin-top: 6px;
    margin-bottom: 8px;
  }
}
