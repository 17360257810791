@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikRegular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikMedium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikSemibold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikBold.woff2') format('woff2');
  font-weight: 700;
}
