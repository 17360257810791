@import '../../../../styles/abstract';
@import '../../../../styles/fonts';
.wrapper {
  .format {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .formatSelector {
      position: relative;
      cursor: pointer;
      width: 18px;
      height: 18px;
      border: 2px solid $gray;
      border-radius: 2px;

      &.selected:after {
        content: '';
        position: absolute;
        left: 1px;
        top: 6px;
        background: $light;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $light, 4px 0 0 $light, 4px -2px 0 $light, 4px -4px 0 $light,
          4px -6px 0 $light, 4px -8px 0 $light;
        transform: rotate(45deg);
      }

      // Box checked
      &.selected {
        background: $primary;
        border-color: $primary;
      }
    }

    .linkWrapper {
      display: inline-block;
    }

    .contentWrapper {
      display: flex;

      align-items: stretch;
      margin-left: 15px;
      width: 100%;
      box-shadow: 0 3px 24px 0 rgba(123, 129, 143, 0.08);
      border: 1px solid $border-color;
      background-color: $light;
      border-radius: 12px;

      &:hover {
        box-shadow: 0 3px 32px 0 rgba(123, 129, 143, 0.28);
      }

      &:hover .editButton {
        display: block;
        background-color: $border-color;
        border-radius: 8px;
      }

      .content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        width: 100%;
        padding: 0;

        .attachments {
          margin-top: 20px;
        }

        .infoTop {
          padding: 18px 24px;
          width: 100%;
        }
      }

      .editButton {
        display: none;
        position: absolute;
        right: 15px;
        top: 8px;
        cursor: pointer;
        padding: 8px;

        // @include respond-to(xl2) {
        //   padding: 20px;
        // }
      }

      .noPreview {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 125px;
        min-height: 80px;
        padding: 10px;
        border-left: 1px solid $border-color;

        .dashed {
          //border: 2px dashed $border-color;
          width: 62px;
          padding: 12px 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        svg * {
          stroke: $border-color;
        }
      }

      .previewWrapper {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 125px;
        min-height: 80px;
        padding: 10px;
        border-left: 1px solid $border-color;

        @media only screen and (max-width: 767px) {
          display: none;
        }

        .previewImage {
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            max-height: 79px;
            margin-bottom: -4px;
          }
        }

        .previewIcon {
          background-color: $border-color;
          width: 100%;
          padding: 12px 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }

        span {
          display: block;
          padding: 3px;
          position: absolute;
          bottom: 6px;
          left: 8px;
          background-color: $body-background;
        }
      }

      .infoWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 24px;
        width: 100%;
        border-top: 1px solid $border-color;

        .icons {
          display: flex;
          align-items: center;
          color: $text-muted;
          margin: 0 -6px;

          font-size: 15px;

          @media only screen and (max-width: 767px) {
            display: none;
          }

          .iconItem {
            margin: 0 6px;
            min-width: 32px;
            display: flex;
            justify-content: center;
            cursor: pointer;

            svg {
              height: 32px;
            }
          }

          .attachments {
            font-weight: 600;
            color: $gray;
            margin-top: 0;
            padding: 4px 8px;
            font-size: 13px;
            border: 1px solid $gray;
            border-radius: 16px;

            svg {
              height: 20px;
              margin-right: 6px;
            }
            // padding: 0 4px;
            // span {
            //   margin-left: 4px;
            // }
          }
        }
        .right {
          display: flex;
          padding-right: 10px;

          button {
            cursor: pointer;
            padding: 5px 10px;
            border: none;
            color: #fff;
            margin-left: 5px;
          }
        }
      }

      &.withNotify {
        border-color: rgba($orange, 0.44);
      }

      &.formatSelected {
        border-color: rgba($primary, 0.54);
      }
    }
  }
  .modalBodyContent {
    .approverWrapper {
      border-bottom: 1px solid $border-color;

      &:last-child {
        border: none;
      }

      .currentApprover {
        margin-left: 80px;
        padding: 20px 0 18px 0;
        border-top: 1px solid $border-color;

        .approvalActionsWrapper {
          display: flex;
          flex-direction: column;

          .approve {
            border-bottom: 1px solid $border-color;
            padding: 16px;
            & > div {
              display: flex;
              align-items: center;
              span {
                margin-left: 8px;
              }
            }
          }

          .disapprove {
            padding: 16px;
            & > div {
              display: flex;
              align-items: center;
              span {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
    .currentApproverWrapper {
      background-color: $body-background;
    }
    .approver {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 24px;
      // border-bottom: 1px solid $border-color;

      .approverInfo {
        display: flex;

        .avatar {
          // width: 40px;
          // height: 40px;
          // border-radius: 50%;
          // background-color: lightblue;
        }
      }

      .approverName {
        margin-bottom: 4px;
        margin-left: 16px;
        font-family: 'Graphik';
        font-weight: 500;
      }

      .approverRole {
        margin-left: 16px;
      }

      .approverRequestTime {
        display: flex;
        align-items: center;
        // justify-content: flex-end;
        color: $text-gray;

        span {
          margin-right: 4px;
        }
      }
    }
  }
}

.approvalStatus {
  display: flex;
  align-items: center;
  margin-right: 8px;

  span {
    display: flex;
    align-items: center;
    font-size: 13px;

    &.approvedByExtOwner {
      background-color: rgba(30, 219, 83, 0.24);
      padding: 4px 6px;
      border-radius: 20px;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 5px;
      border-radius: 50%;
    }

    &.new:before {
      background-color: #b7bbc2;
    }
    &.waiting:before {
      background-color: #ffab0b;
    }
    &.approved:before {
      background-color: #1ec97a;
    }
    &.rejected:before {
      background-color: #f42929;
    }
  }
}
