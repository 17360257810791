@import '../../../../../styles/abstract';

.versioningContainer {
  li {
    width: 240px;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(123, 129, 143, 0.12);
    font-weight: 600;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
  }
}

.content {
  margin: 22px 0;
  display: flex;
  height: calc(100vh - 116px);

  > .left,
  > .right {
    flex: 0 0 auto;
    padding: 5% 0;
  }

  .navFormat {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .left .navFormat {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }

  .right .navFormat {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .box {
    border-radius: 12px;
    box-shadow: 0 3px 24px 0 rgba(123, 129, 143, 0.08);
    background-color: $light;
    border: 1px solid $border-color;
    height: 100%;
  }

  .mainPreview {
    margin: 0 20px;
    flex: 1 0 auto;
    display: flex;
    // height: 100%;

    > .left {
      flex: 1 0 auto;
    }

    > .right {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $border-color;
      flex: 0 0 auto;

      .padding {
        padding: 12px;
      }
    }

    .versioningBox {
      display: flex;
      align-items: center;
      margin-right: 12px;
      margin-top: 3px;
      margin-left: 5px;

      span {
        font-size: 15px;
      }
    }

    .topBar {
      padding: 14px;
      border-bottom: 1px solid $border-color;
      display: flex;
      justify-content: space-between;

      h3 {
        font-size: 20px;
        font-weight: 600;
      }

      .left,
      .right {
        padding: 0;
        display: flex;
        align-items: center;

        h3 {
          margin-right: 12px;
        }

        & > span {
          margin-left: 8px;
        }
      }
    }

    .bottomBar {
      display: flex;
      height: calc(100% - 54px);

      .left {
        border-right: 1px solid $border-color;
        padding: 12px;

        .attachmentsBox {
          background-color: #eeeeee;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          border-radius: 4px;

          span {
            display: flex;
            justify-content: center;
            line-height: 17px;
            background-color: $primary;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            position: absolute;
            bottom: -4px;
            right: -4px;
            font-size: 11px;
            color: #fff;
          }
        }
      }
    }

    .main {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: space-between;

      .mainTop {
        height: calc(100% - 57px);
        position: relative;

        .attachmentsListContainer {
          position: absolute;
          width: 100%;
          background-color: #f9f9f9;
          opacity: 0.96;
          text-align: center;
          padding: 15px;

          span {
            font-size: 15px;
            font-weight: 600;
          }

          .previewListImage {
            display: flex;
            justify-content: center;
            margin-top: 15px;

            .previewSinglePdf {
              width: 120px;
              height: 82px;
              margin: 0 6px;
              position: relative;

              .pspBody {
                height: 49px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px 4px 0 0;
                cursor: pointer;
                background-color: rgba(123, 129, 143, 0.12);
              }

              .pspFooter {
                height: 33px;
                line-height: 31px;
                background-color: #fff;
                border-top: 1px solid rgba(123, 129, 143, 0.12);
                font-size: 12px;
                text-align: center;
                color: $text-gray;
                border-radius: 0 0 4px 4px;
                cursor: pointer;
              }
            }

            .previewSingleImage {
              width: 120px;
              height: 82px;
              margin: 0 6px;
              position: relative;

              img {
                border-radius: 4px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
              }

              &.previewActive:after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 4px;
                bottom: -15px;
                background-color: $primary;
                border-radius: 10px 10px 0 0;
              }
            }

            .previewSingleIframe {
              width: 120px;
              height: 82px;
              box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.12);
              background-color: #f9f9f9;
              margin: 0 6px;
              position: relative;

              .psfBody {
                height: 49px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px 4px 0 0;
                cursor: pointer;
                background-color: rgba(123, 129, 143, 0.12);

                p {
                  font-size: 12px;
                  color: $text-gray;
                  border: 1px solid #b7bbc2;
                  border-radius: 3px;
                  width: 47px;
                  height: 20px;
                  line-height: 18px;
                }
              }

              .psfFooter {
                height: 33px;
                line-height: 31px;
                background-color: #fff;
                border-top: 1px solid rgba(123, 129, 143, 0.12);
                font-size: 12px;
                text-align: center;
                color: $text-gray;
                border-radius: 0 0 4px 4px;
                cursor: pointer;
              }

              &.previewActive:after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 4px;
                bottom: -15px;
                background-color: $primary;
                border-radius: 10px 10px 0 0;
              }
            }
          }
        }
      }

      .topBar {
        padding: 14px;
        border-bottom: 1px solid $border-color;

        .topBarAttachment {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          span {
            font-size: 16px;
            color: $text-gray;
            margin-right: 5px;
          }
        }
      }

      .imagePreview {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: calc(100% - 57px);
        padding: 50px;
        margin: 0 auto;

        & > img {
          object-fit: cover;
          max-height: 100%;
        }
      }

      .iframePreview {
        width: calc(100% - 48px);
        height: 100%;
        max-height: 80%;
        margin: 50px auto 0 auto;

        display: block;

        iframe {
          pointer-events: none;
          border: none;
          width: 100%;
          height: 100%;
        }
      }

      .approval {
        background-color: $light;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $border-color;
        padding: 12px;
        width: 100%;
      }

      .buttonUnderApprovalWrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
      }
    }

    .chatTop {
      padding: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-color;
    }

    .formatChatContainer {
      flex: 1;
      position: relative;
      width: 300px;
      max-height: calc(100% - 54px);
    }
  }
}
