@import 'normalize';
@import 'abstract';
@import 'fonts';

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
ul,
ol,
pre {
  margin: 0;
}
img {
  max-width: 100%;
}
ul {
  list-style: none;
}
ul,
ol {
  padding: 0;
}

select,
button,
input,
textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: 'Graphik', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-color: $body-background;
  color: $text-dark;
}

a {
  text-decoration: none;
  color: $text-dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-dark;
  font-weight: 600;
}

h4 {
  font-size: 16px;
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.cursor-pointer {
  cursor: pointer;
}

label {
  font-size: 13px;
  color: $text-gray;
}

div.public-DraftEditor-content {
  background-color: #fff;
  color: $primary;
  min-height: 160px;
  overflow: auto;
  resize: vertical;
  padding: 24px;
}

.public-DraftEditorPlaceholder-root {
  position: absolute;
  left: 23px;
  top: 73px;
  color: $text-gray;
}

.required {
  color: $primary;
}

.logoClient {
  width: 140px;
  margin-bottom: -10px;

  img {
    width: 100%;
    height: auto;
  }
}

.no-arrow .select__indicator {
  display: none;
}

// Datepicker
.react-datepicker-popper {
  margin-top: 0 !important;
}

.react-datepicker {
  font-family: 'Graphik', sans-serif !important;
  border-color: #7b818f1f !important;
  box-shadow: 0px 0px 8px #7b818f47;
  border-radius: 8px !important;

  .react-datepicker__triangle,
  .react-datepicker__triangle::before {
    border-bottom-color: #fff !important;
  }

  .react-datepicker__triangle {
    margin-top: -5px !important;
    left: auto !important;
    right: 30px;
  }

  .react-datepicker__header {
    padding-top: 16px;
    background-color: #fff;
    border-bottom: 1px solid #7b818f1f;
  }

  .react-datepicker__current-month {
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    padding-left: 14px;
  }

  .react-datepicker__navigation--previous {
    right: 50px;
    left: auto;
  }

  .react-datepicker__navigation {
    margin-top: 7px;
  }

  .react-datepicker__day-names {
    margin-top: 8px;

    .react-datepicker__day-name {
      color: #7b818f;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover .react-datepicker__day--selected:hover {
    background-color: $primary !important;
    border-radius: 50% !important;
    font-weight: 500;
  }
}
