@import './../../styles/abstract';

.topTitle {
  height: 52px;
  background-color: $light;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;
  h3 {
    padding: 0;
    margin-left: 14px;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .steps {
    list-style-type: none;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 32px 0;

    .stepOne {
      position: relative;
      &::after {
        position: absolute;
        content: '';
        right: -32%;
        top: 22%;
        width: 70%;
        border-bottom: 2px solid $border-color;
      }
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 30px;
      color: $gray;
      font-size: 15px;
      color: $text-gray-light;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        margin-bottom: 24px;
        width: 32px;
        height: 32px;
        background-color: #eeeeee;
        color: $text-gray-light;
        border-radius: 50%;
        font-weight: 500;
      }

      &.active {
        color: $text-gray;
        span {
          background-color: $primary;
          color: $light;
          box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);
        }
      }
    }
  }

  .box {
    width: 800px;
    margin-bottom: 80px;
    border-radius: 8px;
    background-color: $light;
  }
}

.editCreativityWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .editCreativityTitle {
    padding: 16.5px 0 16.5px 17px;
    background-color: $light;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color;
  }

  .creativityData {
    border-radius: 12px;
    background-color: $light;
    margin: 40px auto;
    height: calc(100vh - 272px);
    display: flex;
    min-height: 100%;
    flex: 1 0 90%;
    box-shadow: 0 3px 24px 0 rgba(123, 129, 143, 0.08);

    .creativityDataLeft {
      border-right: 1px solid $border-color;

      & > div {
        padding: 32px;
      }

      h4 {
        margin-bottom: 33px;
      }

      .input {
        margin-bottom: 31px;
      }
    }

    .creativityDataRight {
      overflow-y: auto;
      flex: 1 0 auto;
      .titleBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 22px;
        padding-left: 32px;
      }

      .titleBarIcons {
        display: flex;
        align-items: center;
        margin-left: 22px;
      }
      & > div {
      }

      .subTitleBar {
        display: flex;
        justify-content: space-between;
        color: $text-gray;
        font-size: 13px;
        font-weight: 500;
        padding: 48px 44px 12px 44px;
        border-bottom: 1px solid $border-color;

        .titlePhaseName {
          display: block;
          width: 400px;
          padding-left: 14px;

          @media only screen and (min-width: 1440px) {
            width: 650px;
          }
        }

        span {
          color: $text-gray;
          font-weight: 500;
          font-size: 13px;
        }

        .inputDate {
          padding-left: 40px;
        }

        .titleDays {
          margin-left: auto;
        }

        .subTitleBarInfo {
          display: flex;
          font-size: 13px;
          font-weight: 500;
          padding-right: 24px;
          & > p {
            margin-right: 70px;
          }
        }
      }

      .content {
        ul li {
          padding: 8px;
          border-bottom: 1px solid $border-color;
          display: flex;
          align-items: center;
          // justify-content: space-between;

          .phaseName {
            width: 400px;
            padding-left: 4px;

            @media only screen and (min-width: 1440px) {
              width: 650px;
            }
          }

          .inputDate {
            padding-left: 40px;
          }

          .options {
            cursor: pointer;
            margin-left: auto;
          }

          .phaseDays {
            color: #7b818f;
            font-size: 15px;
            margin-left: 32px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }

        .stepsItem {
          position: relative;
          display: flex;
          align-items: center;
          padding: 8px 22px 8px 4px;
          border-bottom: 1px solid $border-color;

          .options {
            display: none;
            position: absolute;
            justify-content: flex-end;
            align-items: center;
            width: 80px;
            right: 0;
            bottom: 0;
            top: 0;
            background: #f9f9f9;
            padding-right: 12px;

            background: linear-gradient(
              to left,
              rgba(249, 249, 249, 1) 40%,
              rgba(125, 185, 232, 0) 100%
            );

            span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-left: 5px;
              cursor: pointer;
            }
          }

          /* &:first-child {
        .phaseName::before {
          content: 'Fase';
        }
        .inputDate::before {
          content: 'Data chiusura prevista';
        }
        .phaseDays::before {
          content: 'Giorni';
        }

        .phaseName::before,
        .inputDate::before,
        .phaseDays::before {
          position: absolute;
          top: -24px;
          font-size: 13px;
          color: $text-gray;
          font-weight: 500;
        }
      } */

          .phaseDays {
            color: #7b818f;
            font-size: 15px;
            margin-left: auto;
            padding-left: 40px;
          }

          &:hover {
            background: #f9f9f9;

            .options {
              display: flex;
            }
          }
        }
      }
    }
  }

  .footer {
    height: 64px;
    background-color: $light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    border-top: 1px solid $border-color;

    .editDelete {
      display: flex;
      align-items: center;
      color: $text-gray;
      padding: 8px;

      span {
        margin-left: 8px;
        font-size: 13px;
      }

      &:hover {
        background-color: $border-color;
        border-radius: 8px;
      }
    }

    .editBtn {
      display: flex;
      align-items: center;
      padding-right: 12px;
    }
  }
}
