@import '../../styles/abstract';

.container {
  display: flex;
  //   width: calc(100vw + 135px);
  background-color: $light;
  height: 100vh;

  .accountMenu {
    border-right: 1px solid $border-color;
    height: 100%;
    width: 300px;

    @include respond-to(md) {
      display: none;
    }

    .accountMenuTop {
      height: 72px;
      padding: 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $border-color;
    }

    & ul li a {
      padding: 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $border-color;

      &:hover,
      &:global(.active) {
        position: relative;
        background-color: $body-background;

        svg * {
          stroke: $primary;
        }
      }

      &:global(.active) {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          background-color: $primary;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          width: 4px;
          height: 32px;
        }
      }

      span {
        margin-left: 14px;
      }
    }
  }

  .accountInfo {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: $body-background;

    .accountInfoTop {
      background-color: $light;
      height: 72px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-color;
      flex: 1 0 auto;
    }

    .accountInfoMain {
      display: flex;
      flex-direction: column;
      padding: 30px;
      overflow-y: auto;
      height: 100vh;
      align-items: center;

      .accountInfoMainWrapper {
        padding: 30px;
        width: 70%;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        flex: 1 0 auto;
        box-shadow: 0 3px 24px 0 rgba(123, 129, 143, 0.08);
        border: 1px solid $border-color;
        background-color: $light;
        border-radius: 12px;

        @media only screen and (max-width: 768px) {
          margin-bottom: 32px;
        }

        @media only screen and (min-width: 768px), (max-width: 1335px) {
          width: 100%;
        }
        @media only screen and (min-width: 1336px) {
          width: 80%;
        }
        @media only screen and (min-width: 1920px) {
          width: 55%;
        }
        .accountInfoMainEdit {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .editIcon {
            padding: 8px;

            &:hover {
              background-color: $border-color;
              border-radius: 8px;
            }
          }
        }

        .accountInfoMainBottom {
          display: flex;
          flex-direction: row;
          margin-top: 30px;

          @include respond-to(md) {
            display: flex;
            flex-direction: column;
            // margin-top: 0;
          }

          .accountData {
            margin-left: 64px;
            flex: 1 0 auto;

            @media screen and (max-width: 768px) {
              margin-left: 0;
              margin-top: 20px;
            }

            form {
              width: 80%;
              .userNameInputWrapper {
                border-bottom: 1px solid $border-color;
              }
              .userNameInput {
                & > div {
                  margin-bottom: 32px;
                }
              }

              .userDataInput {
                & > div {
                  margin-top: 32px;
                }
              }
            }

            .userName {
              border-bottom: 1px solid $border-color;

              @media screen and (max-width: 768px) {
              }

              li {
                margin-bottom: 20px;
                & > div {
                  margin-bottom: 8px;
                }

                .label {
                  font-size: 13px;
                  color: $text-gray;
                }

                .data {
                  font-size: 16px;
                }
              }
            }

            .userData {
              @include respond-to(md) {
                // padding-top: 20px;
              }
              li {
                padding-top: 20px;

                & > div {
                  margin-top: 8px;
                }

                .label {
                  font-size: 13px;
                  color: $text-gray;
                }

                .data {
                  font-size: 16px;
                }
              }
            }
          }

          .accountInfoAvatar {
            // padding-right: 60px;
            // width: 80px;
            // height: 80px;
            // border-radius: 50%;
            // background-color: lightblue;
            position: relative;
            @include respond-to(md) {
              display: flex;
              justify-content: center;
              padding: 20px 0;
              border-bottom: 1px solid $border-color;
            }

            .accountInfoEdit {
              position: absolute;
              background-color: $light;
              width: 38px;
              height: 38px;
              border-radius: 50%;
              padding: 6px;
              z-index: 100;
              top: 96px;
              right: 4px;
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
              display: flex;
              justify-content: center;
              align-items: center;

              @media only screen and (min-width: 320px) and (max-width: 480px) {
                top: 112px;
                right: 70px;
              }

              @media only screen and (min-width: 481px) and (max-width: 640px) {
                top: 112px;
                right: 108px;
              }
              @media only screen and (min-width: 767px) and (max-width: 780px) {
                top: 112px;
                right: 260px;
              }
              // @include respond-to('md') {
              //   top: 112px;
              //   right: 108px;
              // }
              // @include respond-to('sm') {
              //   top: 112px;
              //   right: 70px;
              // }

              svg {
                width: 19px;
                height: 19px;
              }
            }
          }
        }
      }
    }

    .accountInfoBottom {
      background-color: $light;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      border-top: 1px solid $border-color;

      @include respond-to('md') {
        padding-bottom: 64px;
      }

      .accountInfoBottomDelete {
        display: flex;
        align-items: center;
        color: $text-gray;
        padding: 8px;

        &:hover {
          background-color: $body-background;
          border-radius: 4px;
        }
        span {
          margin-left: 12px;
          cursor: pointer;
        }
      }
      .accountInfoBottomDelete,
      .accountInfoBottomActions {
        display: flex;
        align-items: center;
      }
    }
  }
}
