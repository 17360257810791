@import '../../styles/abstract';

.container {
  display: flex;
  //   width: calc(100vw + 135px);
  background-color: $light;
  height: 100vh;

  .accountMenu {
    border-right: 1px solid $border-color;
    height: 100%;
    width: 15%;
    .accountMenuTop {
      height: 72px;
      padding: 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $border-color;
    }

    & ul li a {
      padding: 20px;
      display: flex;
      align-items: center;

      &:hover,
      &:global(.active) {
        background-color: $body-background;

        svg * {
          stroke: $primary;
        }
      }

      span {
        margin-left: 14px;
      }
    }
  }

  .accountInfo {
    width: 85%;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .accountInfoTop {
      height: 72px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-color;
    }

    .accountInfoMain {
      background-color: $body-background;
      height: calc(100vh - 126px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px;

      .accountInfoMainWrapper {
        padding: 30px;
        width: 70%;
        display: flex;
        flex-direction: column;
        // margin: 30px auto;

        background-color: $light;
        border-radius: 12px;
        height: 100%;

        .accountInfoMainEdit {
          display: flex;
          justify-content: space-between;
        }

        .accountInfoMainBottom {
          display: flex;
          flex-direction: row;
          margin-top: 30px;

          .accountInfoAvatar {
            margin-right: 60px;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-color: lightblue;
            position: relative;

            .accountInfoEdit {
              position: absolute;
              background-color: $light;

              border-radius: 50%;
              padding: 6px;
              z-index: 100;
              bottom: 0;
              right: -2px;
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }

    .accountInfoBottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;

      .accountInfoBottomDelete {
        display: flex;
        align-items: center;
        color: $text-gray;
        padding: 8px;

        &:hover {
          background-color: $body-background;
          border-radius: 4px;
        }
        span {
          margin-left: 12px;
          cursor: pointer;
        }
      }
      .accountInfoBottomDelete,
      .accountInfoBottomActions {
        display: flex;
        align-items: center;
      }
    }
  }
}
