@import '../../../styles/abstract';

.wrapper {
  .row {
    height: 54px;
    background-color: $light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid $border-color;
  }

  .doubleTabs {
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid $border-color;

    li {
      display: flex;
      cursor: pointer;
      margin: 0 15px;
      padding: 16px 5px;
      border-bottom: 3px solid transparent;
      position: relative;
      top: 2px;

      &.active {
        border-color: #0d101f;
      }

      .unreadBadge {
        position: relative;
        margin-left: 4px;
      }
    }
  }

  .chatListContainer {
    .chatList {
      overflow: auto;
      height: 100vh;
      padding-bottom: 124px;

      .chatName {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        color: $gray;
        border-bottom: 1px solid $border-color;
        cursor: pointer;

        p,
        strong {
          color: #0d101f;
          font-size: 15px;
          margin-bottom: 8px;
        }

        .chatNameLeft {
          position: relative;
          display: flex;
          flex-direction: column;
        }

        .chatNameRight {
          position: relative;

          .unreadBadge {
            position: absolute;
            right: 0;
            top: 23px;
          }
        }
      }
    }
  }

  .unreadBadge {
    min-width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $primary;
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
