@import '../../../styles/abstract';

.textarea {
  margin-top: 4px;
  background-color: rgba(123, 129, 143, 0.08);
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 12px;
  height: 128px;
  width: 100%;
  font-size: 15px;
  resize: vertical;
}
