@import '../../../styles/abstract';

.customCheckbox {
  position: absolute;
  opacity: 1;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 1x;
    display: flex !important;
    align-items: center;

    > span {
      display: inline-block !important;
      color: $primary;
      margin-left: 4px;
    }
  }

  // Box
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: solid 1.5px #7b818f;
    background-color: rgba(123, 129, 143, 0);
  }

  // Box hover
  &:hover + label:before {
    background: lighten($primary, 40);
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: $primary;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark.
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 7px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}
