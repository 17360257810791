@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

$breakpoints: (
  'sm': 640px,
  'md': 768px,
  'lg': 1024px,
  'xl': 1280px,
  'xl2': 1400px,
  'xxl': 1920px,
);

// Colors
$body-background: #f9f9f9;
$border-color: rgba(123, 129, 143, 0.12);
$input-background: rgba(123, 129, 143, 0.08);

$text-dark: #0d101f;
$text-gray: #7b818f;
$text-muted: #b7bbc2;
$text-gray-light: rgba(123, 129, 143, 0.54);
$text-light: #fff;

$primary: #29b9d0;
$red: #f42929;
$green: #1ec97a;
$orange: #ffab0b;
$dark: #0d101f;
$gray: #7b818f;
$light: #fff;
