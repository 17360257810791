@import '../../styles/abstract';

.sidebarWrapper {
  position: fixed;
  left: 0;
  background-color: $dark;
  z-index: 10;
  height: 100%;
  display: flex;

  .notificationsToastWrapper {
    position: absolute;
    bottom: 0;
    left: 60px;
    padding-left: 12px;

    @include respond-to('md') {
      position: fixed;
      width: 100%;
      top: 12px;
      bottom: auto;
      left: 0;
      padding-right: 12px;
    }

    &.moreNotification {
      padding-bottom: 5px;
    }

    .toastDuplicate {
      width: 335px;
      height: 94px;
      display: block;
      position: absolute;
      bottom: 10px;
      z-index: -1;
      margin-left: 7px;
      border-radius: 8px;
      box-shadow: 0px 3px 10px #7b818f1f;
      border: 1px solid #7b818f1f;
      background-color: #fff;
    }
  }
}

.sidebar {
  padding: 20px 0;
  width: 60px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  color: $text-light;

  .topWrapper {
    display: flex;
    flex-direction: column;

    .notificationIcon {
      cursor: pointer;
      position: relative;
      margin-top: 16px;

      &.notificationActive {
        svg {
          animation: bellRings 5s infinite;
          transform-origin: top;
        }
      }

      span {
        position: absolute;
        bottom: -3px;
        right: 13px;
        width: 15px;
        height: 15px;
        background-color: $primary;
        border-radius: 50%;
        font-size: 11px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light;
      }
    }

    @keyframes bellRings {
      0% {
        transform: rotate(0deg);
      }
      3% {
        transform: rotate(5deg);
      }
      6% {
        transform: rotate(15deg);
      }
      9% {
        transform: rotate(-15deg);
      }
      12% {
        transform: rotate(15deg);
      }
      15% {
        transform: rotate(-15deg);
      }
      18% {
        transform: rotate(15deg);
      }
      21% {
        transform: rotate(-15deg);
      }
      24% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  a {
    color: $text-light;
    padding: 10px 0;
    position: relative;
    &:global(.active) {
      &::before {
        content: '';
        position: absolute;
        left: 0;

        background-color: $primary;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        width: 4px;
        height: 32px;
      }
    }
  }

  .bottomWrapper {
    .profile {
      display: inline-block;
    }

    span {
      margin-top: 12px;
      display: block;
    }
  }

  @media only screen and (max-width: 769px) {
    display: none;
  }
}

.notificationExpanded {
  position: fixed;
  left: 60px;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  z-index: 10;

  .notificationContent {
    flex: 0 0 360px;
    background-color: $light;
    box-shadow: 0 0 24px 0 rgba(123, 129, 143, 0.16);

    .notificationHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      height: 54px;
      border-bottom: 1px solid rgba(123, 129, 143, 0.12);

      h3 {
        font-size: 20px;
      }

      @include respond-to('md') {
        justify-content: center;
      }
    }

    .notificationsList {
      height: calc(100% - 54px);
      overflow-y: auto;
    }
  }

  .notificationOverlay {
    width: 100%;
    background-color: rgba(123, 129, 143, 0.12);
  }

  @include respond-to(md) {
    left: 0;

    .notificationContent {
      flex: 1;
    }

    .notificationOverlay {
      display: none;
    }
  }
}

.expandedSidebar {
  width: 420px;
  border-left: 1px solid $border-color;
  overflow-y: auto;
  position: relative;

  @media screen and (max-width: 1919px) {
    width: 300px;
  }
  // @include respond-to('xxl') {
  //   width: 300px;
  // }

  h3 {
    padding: 18px 20px;
    color: $light;
  }

  .list {
    list-style: none;

    a {
      display: block;
      padding: 16px 20px;
      color: $gray;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;

      &:hover,
      &:global(.active) {
        background-color: rgba(123, 129, 143, 0.54);
        color: $light;
      }
    }
  }

  .addCampaign {
    padding: 20px;

    .link {
      color: $primary;

      display: flex;
      align-items: center;

      font-size: 16px;

      span {
        margin-left: 6px;
      }
    }
  }

  .collapseSidebar {
    position: fixed;
    left: 420px;
    bottom: 18px;
    z-index: 999;
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 10px;

    &:hover {
      box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);
    }

    @media screen and (max-width: 1919px) {
      left: 300px;
    }
    // @include respond-to('xxl') {
    //   left: 300px;
    // }
  }
}

.iconExpandSidebar {
  height: 24px;
}
