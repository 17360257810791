@import '../../../styles/abstract';

.wrapper {
  margin-top: 30px;
  width: 80%;

  .box {
    display: flex;
    flex: 1 0 auto;
    // justify-content: space-between;
    border: 1px solid $border-color;
    border-radius: 12px;
    box-shadow: 0 0 25px 0 rgba(123, 129, 143, 0.16);

    margin-bottom: 15px;
    color: inherit;
    text-decoration: none;
    background-color: $light;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    @include respond-to('lg') {
      flex-direction: column;
    }

    .titleBoxCtn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 25%;
      border-right: 1px solid $border-color;
      padding: 24px;

      h3 {
        margin: 0;
      }

      @include respond-to('lg') {
        width: 100%;
        border-bottom: 1px solid $border-color;
        border-right: none;
      }
    }

    .boxBarCtn {
      width: 75%;
      display: flex;
      padding: 20px;
      justify-content: space-between;

      @include respond-to('sm') {
        flex-wrap: wrap;
      }

      h4 {
        padding-bottom: 8px;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: left;
        color: $text-gray;
      }

      span {
        font-size: 13px;
      }

      @include respond-to('lg') {
        width: 100%;
      }
    }

    .titleBox {
      width: 100%;
    }

    .bottomBoxBar {
      padding: 12px 24px;
      color: rgba(123, 129, 143, 0.54);
      border-top: 1px solid $border-color;
      font-size: 13px;
    }

    .boxBar {
      padding-bottom: 15px;

      @include respond-to('sm') {
        width: 100%;
      }
    }

    .steps {
      // margin-top: 30px;
      list-style-type: none;

      li {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 3px;
        background-color: $primary;
        opacity: 0.24;
        margin-right: 4px;

        &.completed {
          opacity: 1;
        }

        .stepBubble {
          width: 95px;
          top: -35px;
          position: absolute;

          padding: 5px;
        }
      }
    }

    .stepsInfo {
      display: flex;
      flex-direction: column;
      color: $gray;
      gap: 8px;
    }
  }

  .addBox {
    display: flex;
    justify-content: center;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    height: 107px;
    padding: 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: $gray;
    flex-direction: column;

    &:hover {
      background-color: rgba(123, 129, 143, 0.08);
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      // width: 30px;
      // height: 30px;
      // border-radius: 50%;
      // border: 1px solid rgba(0, 0, 0, 0.3);
      // border-radius: 50%;
    }
  }

  @include respond-to('md') {
    width: 100%;
    margin-top: 0;

    .box {
      .titleBoxCtn {
        padding: 20px;
      }

      .boxBarCtnInner {
        flex-direction: column;
        align-items: start;
      }
    }

    .steps {
      margin-bottom: 24px;
    }
  }
}
