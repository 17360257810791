@import '../../../styles/abstract';

.labelWrapper {
  position: relative;
  display: block;

  > span {
    display: inline-block !important;
    color: $primary;
  }

  .wrapper {
    margin-top: 4px;
    border: none;
    border-radius: 8px;
    width: 100%;
    font-size: 15px;

    &.error {
      border: 1px solid $red;
    }

    [class$='-control'] {
      background-color: $input-background;
      border: none;
      border-radius: 8px;
      box-shadow: none;

      &:global(.select__control--menu-is-open) {
        background-color: $light;
        box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);
      }

      &:global(.select__control--menu-is-focused) {
        box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);
      }

      :global(.select__indicator-separator) {
        display: none;
      }
    }

    [class$='-menu'] {
      background-color: $light;
      border: 1px solid $border-color;
      border-radius: 8px;
      box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);
    }

    [class$='-menu']:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 100%;
      left: 20px;
      border: 6px solid transparent;
      border-top: none;
      border-bottom-color: $light;
      filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }
  }
}
