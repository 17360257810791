@import '../../../styles/abstract';

.wrapper {
  margin-left: 20%;
  width: 60%;

  .actions {
    display: flex;
    gap: 16px;

    .actionChat {
      display: none;
    }

    @include respond-to('xl') {
      .actionChat {
        display: block;
        z-index: 1000;
      }
    }
  }

  @include respond-to('md') {
    &.formatsClose {
      display: none;
    }
  }

  @include respond-to('md') {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (min-width: 769px) and (max-width: 1281px) {
    margin-left: 30%;
    width: 70%;
  }

  .deleteInfo {
    padding: 40px 107px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .deleteIcon {
      border: 1px solid transparent;
      padding: 12px;
      border-radius: 50%;
      background-color: $light;
      box-shadow: 0 0 25px 0 rgba(123, 129, 143, 0.16);
      margin-bottom: 32px;
    }
  }

  .operationsInfo {
    padding: 40px;
    background-color: $input-background;
    p {
      margin-bottom: 32px;
    }

    ul li {
      color: $text-gray;
      margin-top: 18px;
      display: flex;
      align-items: center;

      span {
        margin-right: 12px;
        background-color: $red;
        border-radius: 4px;
      }
    }
  }

  .row {
    height: 54px;
    background-color: $light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid $border-color;
  }

  .formatsWrapper {
    padding: 0 20px;
    height: calc(100vh - 136px);
    overflow-y: scroll;
    padding-bottom: 80px;

    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 8;
    }

    .newFormatWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 20px;

      justify-content: space-between;

      .allFormats {
        display: flex;
      }

      .selectAll {
        position: relative;
        cursor: pointer;
        width: 18px;
        height: 18px;
        border: 2px solid $gray;
        border-radius: 2px;
        margin-right: 15px;

        &.selected:after {
          content: '';
          position: absolute;
          left: 1px;
          top: 6px;
          background: $light;
          width: 2px;
          height: 2px;
          box-shadow: 2px 0 0 $light, 4px 0 0 $light, 4px -2px 0 $light, 4px -4px 0 $light,
            4px -6px 0 $light, 4px -8px 0 $light;
          transform: rotate(45deg);
        }

        // Box checked
        &.selected {
          background: $primary;
          border-color: $primary;
        }
        &.hidden {
          display: none;
        }
      }

      .newFormat {
        // width: 100%;

        // border: 1px dotted rgba(0, 0, 0, 0.3);
        cursor: pointer;

        @include respond-to('md') {
          display: none;
        }
      }
    }

    .formatsList {
      margin-top: 25px;
    }

    .selectedElementsBar {
      display: flex;

      align-items: center;
      position: fixed;
      bottom: 0;
      left: calc(20% + 48px);
      width: calc(60% - 36px);
      padding: 20px;
      background-color: $light;
      border-top: 1px solid $border-color;

      @media only screen and (max-width: 768px) {
        left: 0;
        width: 100%;
        border-top-left-radius: 38px;
        border-top-right-radius: 38px;
        box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.28);
        height: 178px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 30;
      }

      @media only screen and (min-width: 769px) {
        left: calc(30% + 42px);
        width: calc(100% - (30% + 60px));
        right: 0;
        bottom: 0;
      }

      @media only screen and (min-width: 1280px) {
        left: calc(20% + 48px);
        width: calc(60% - 36px);
        bottom: 0;
      }

      @media only screen and (min-width: 1281px), (max-width: 1919px) {
        :global(.sidebarExpanded) {
          left: calc(20% + 288px);
          width: calc((60% - 36px) - 20%);
          bottom: 0;
        }
      }
      :global(.sidebarExpanded) {
        @media screen and (min-width: 1920px) {
          width: 45%;
          left: calc(20% + 384px);
        }
      }

      .selectedElementsBarLeft {
        flex: 1 0 0%;
        width: 100%;
        font-size: 14px;
        letter-spacing: 0.56px;
        color: $text-gray;

        @media only screen and (max-width: 768px) {
          text-align: center;
          border-bottom: 1px solid $border-color;
          margin-bottom: 18px;
        }
      }

      .sendForApproval {
        flex: 1 0 0%;
        width: 100%;
        display: flex;
        justify-content: center;

        button {
          cursor: pointer;
          padding: 6px 24px;
          border-radius: 20px;
          height: 40px;
          background-color: $primary;
          border: none;
          color: $light;
          display: flex;
          align-items: center;

          div {
            margin-left: 12px;
          }
        }
      }

      .selectedElementsBarRight {
        flex: 1 0 0%;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        & > div {
          padding: 8px;
          :hover {
            background-color: $body-background;
            cursor: pointer;
          }
        }

        @media only screen and (max-width: 768px) {
          justify-content: center;
        }
      }
    }
  }

  .form {
    padding: 24px 24px 80px 24px;
  }
}
