@import '../../../styles/abstract';

.header {
  border-bottom: 1px solid $border-color;

  .headerContent {
    height: 130px;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-size: 100%;
    background-position: center center;
    transition: all 0.2s;

    @include respond-to('md') {
      height: 86px;
    }
  }
}

.campaignInfo {
  padding: 24px;
  position: relative;

  @include respond-to('sm') {
    overflow: hidden;
  }

  .campaignInfoTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 20px;
  }

  .campaignCreativities {
    display: flex;
    margin: 0 -14px;

    .creativityBox {
      width: 33.33333%;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      flex: 0 0 auto;
      padding: 8px 12px;
      border-radius: 6px;

      &:hover {
        background-color: #f9f9f9;
        box-shadow: 0px 2px 10px #7b818f29;
      }

      @include respond-to('sm') {
        width: 50%;
      }

      h3 {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 500;
      }

      p {
        margin-bottom: 10px;
        font-size: 12px;
        color: $text-gray;
      }
      .creativityPhases {
        ul {
          height: 15px;
          list-style: none;
          margin: 0 -2px;
          display: flex;
          // overflow: auto;
          flex-direction: row;
          li {
            flex: 0 0 auto;
            width: 19px;
            height: 3px;
            margin: 0 2px;
            background-color: $primary;
            opacity: 0.24;

            &.completed {
              opacity: 1;
            }
          }
        }
      }

      .nextPhaseSchedule {
        color: $text-gray;
        font-size: 11px;
        display: flex;
        align-items: center;

        :first-child {
          margin-right: 3px;
        }
      }
    }
  }

  .campaignUsers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .userAvatar {
      display: flex;

      & > div {
        margin-right: -8px;
        border: 1px solid $light;
      }
      span {
        display: none;
      }

      .rest {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $gray;
        z-index: 10;
        margin-right: -8px;
        background-color: $light;
        color: $text-gray;
        font-weight: 500;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
