@import '../../../styles/abstract';

.button {
  border: 1px solid $primary;
  background-color: $primary;
  color: $text-light;
  padding: 0 24px;
  min-height: 40px;
  cursor: pointer;
  border-radius: 50px;
  font-size: 13px;
  display: flex;
  align-items: center;

  &:hover {
    box-shadow: 0 0 8px 0 rgba(123, 129, 143, 0.12);
    background-color: #1b9cb1;
  }

  &.noHover:hover {
    box-shadow: none;
  }

  &.inLoad {
    position: relative;
    padding-left: 48px;
    pointer-events: none;
  }

  &.onlyIcon {
    padding: 0 12px;

    span {
      margin-right: 0 !important;
    }

    svg {
      width: 18px;
    }
  }

  &.styleTransparent {
    background-color: transparent;
    color: $text-gray;
    border-color: $border-color;

    &:hover {
      box-shadow: none;
      background-color: rgba(123, 129, 143, 0.08);
    }
  }

  &.danger {
    border: 1px solid $red;
    background-color: $red;
  }

  &.cancel {
    border: 1px solid $border-color;
    background-color: $light;
    color: $text-gray;

    &:hover {
      box-shadow: none;
      background-color: rgba(123, 129, 143, 0.08);
    }
  }

  &.dashed {
    border: 2px dashed $border-color;
    border-radius: 8px;
    padding: 12px;
  }

  &.fullwidth {
    width: 100%;
  }

  &.fullwidth.icon {
    justify-content: center;
  }

  &.icon span {
    display: block;
    margin-right: 6px;
  }

  &.icon.iconRight span {
    margin-left: 8px;
    margin-right: 0;
  }

  &.floatTopRight {
    position: absolute;
    top: -20px;
    right: 20px;
    padding: 0;
    width: 40px;
    justify-content: center;

    &.icon span {
      margin-right: 0;
    }
  }

  &.floatBottomRight {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 0;
    width: 40px;
    justify-content: center;

    &.icon span {
      margin-right: 0;
    }
  }

  .loading {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 4px;
    left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(13, 16, 31, 0.08);
    border-radius: 50%;

    svg {
      animation: rotation 1s infinite linear;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
