@import '../../../styles/abstract';

.alert {
  background-color: rgba($red, 0.08);
  color: $red;
  display: flex;
  align-items: center;
  padding: 24px;
  border-radius: 8px;

  span {
    margin-left: 16px;
  }
}
