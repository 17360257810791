@import '../../../styles//abstract';

.icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.noflex {
    display: block;
  }

  &.gray * {
    stroke: $gray;
  }

  &.red * {
    stroke: $red;
  }

  &.green * {
    stroke: $green;
  }

  &.orange * {
    stroke: $orange;
  }

  &.primary * {
    stroke: $primary;
  }

  &.light * {
    stroke: $light;
  }

  &.danger * {
    stroke: $red;
  }

  &.isInputIcon {
    position: absolute;
    right: 12px;
    bottom: 3px;
  }

  &.size_20 * {
    width: 20px;
    height: 20px;
  }
  &.size_22 * {
    width: 22px;
    height: 22px;
  }

  &.pointer {
    cursor: default;
  }
}
